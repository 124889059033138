import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  XAxisProps,
  YAxisProps,
  TooltipProps,
} from "recharts";

import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";

import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material";

import { BarPropsType, LineProps } from "./types";
import {
  ContentType,
  Formatter,
} from "recharts/types/component/DefaultLegendContent";

interface IProps {
  data: any[];
  percentage?: number;
  xAxisProps: XAxisProps;
  tooltipProps?: TooltipProps<ValueType, NameType>;
  lineProps: LineProps;
  barProps: BarPropsType;
  yAxisProps?: YAxisProps;
  endYaxisProps?: YAxisProps;
  height?: number;
  legendProps?: {
    content?: ContentType;
    formatter?: Formatter;
  };
  primaryColor?: string;
  secondaryColor?: string;
  barColor?: string;
  isAnimationActive?: boolean;
}

export default function ComposedBarLineChart({
  data,
  percentage = 100,
  tooltipProps,
  xAxisProps,
  lineProps,
  barProps,
  yAxisProps,
  endYaxisProps,
  height,
  legendProps,
  primaryColor,
  secondaryColor,
  barColor,
  isAnimationActive = true,
}: IProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const linePrimary = primaryColor ?? theme.palette.primary.main;
  const lineSecondary = secondaryColor ?? linePrimary;
  const stopOpacity = secondaryColor ? 1 : 0.3;
  const stopProps = { stopColor: lineSecondary, stopOpacity };

  const defaultYAxisProps = {
    stroke: theme.palette.text.disabled,
    color: theme.palette.text.disabled,
    tickSize: 12,
    tickMargin: 5,
    tick: { fontSize: 12 },
  };

  return (
    <ResponsiveContainer width="100%" height={height ?? 500}>
      <ComposedChart stackOffset="expand" data={data}>
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
            <stop offset="0%" stopColor={linePrimary} />
            <stop offset={`${percentage}%`} stopColor={linePrimary} />
            <stop offset={`${percentage}%`} {...stopProps} />
            <stop offset="100%" {...stopProps} />
          </linearGradient>
        </defs>

        <CartesianGrid stroke={theme.palette.grey[200]} />

        <XAxis
          stroke={theme.palette.text.disabled}
          tick={{ fontSize: 12 }}
          tickSize={10}
          tickMargin={5}
          {...xAxisProps}
        />
        <YAxis {...defaultYAxisProps} width={70} yAxisId={0} {...yAxisProps} />

        <YAxis
          {...defaultYAxisProps}
          width={39}
          yAxisId={1}
          orientation={"right"}
          {...endYaxisProps}
        />

        <Tooltip {...tooltipProps} />
        <Legend {...legendProps} />

        <Bar
          isAnimationActive={isAnimationActive}
          yAxisId={1}
          fill={barColor ?? (isDarkMode ? grey[500] : grey[300])}
          barSize={3}
          {...barProps}
        />
        <Line
          isAnimationActive={isAnimationActive}
          yAxisId={0}
          stroke="url(#gradient)"
          type="monotone"
          strokeWidth={5}
          dot={false}
          {...lineProps}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
