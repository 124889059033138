import { Typography } from "@mui/material";
import { DisabledBox, Holder, LabelHolder, LightBox, MainBox } from "./styles";

interface IProps {
  primaryColor?: string;
}

export default function RenderLegend({ primaryColor }: IProps) {
  return (
    <Holder>
      <LabelHolder>
        <DisabledBox />
        <Typography variant="caption">Average Concurrency</Typography>
      </LabelHolder>
      <LabelHolder>
        <MainBox primaryColor={primaryColor} />
        <Typography variant="caption">Bandwidth Used</Typography>
      </LabelHolder>
      <LabelHolder>
        <LightBox primaryColor={primaryColor} />
        <Typography variant="caption">Projected Bandwidth Use</Typography>
      </LabelHolder>
    </Holder>
  );
}
