import { useSession } from "@/security/RouteGuards";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { subDays, subWeeks } from "date-fns";
import TimezoneUtils, { compareDates } from "@/utils/TimezoneUtils";
import { useBatchUrlState } from "@/hooks/useUrlState";

export type PresetTypes =
  | "this_month"
  | "last_month"
  | "last_7"
  | "last_24_h"
  | "";
interface IProps {
  justifyContent?: "left" | "right" | "center";
  timestampGte: Date | null;
  timestampLte: Date | null;
  presetFilters?: PresetTypes[];
}

export default function DatePresets({
  timestampGte,
  timestampLte,
  presetFilters,
}: IProps) {
  const { subscription } = useSession();
  const { start_date, end_date } = subscription || {};
  const startDate = TimezoneUtils.zonedDate(start_date);
  const endDate = TimezoneUtils.zonedDate(end_date);
  const today = TimezoneUtils.zonedDate();
  const yesterday = subDays(TimezoneUtils.zonedDate(), 1);
  const lastWeek = subWeeks(TimezoneUtils.zonedDate(), 1);
  const lastMonth = subDays(TimezoneUtils.zonedDate(start_date), 30);

  const [, setParams] = useBatchUrlState([
    ["activityTimestampLte"],
    ["activityTimestampGte"],
  ]);

  const setDateRange = (v: Date | null, i: Date | null) => {
    if (!v || !i) return;
    setParams([
      ["activityTimestampLte", v.toISOString()],
      ["activityTimestampGte", i.toISOString()],
    ]);
  };

  const handleSelect = (e: SelectChangeEvent) => {
    const { value } = e.target;

    switch (true) {
      case value === "this_month":
        setDateRange(endDate, startDate);
        break;

      case value === "last_24_h":
        setDateRange(today, yesterday);
        break;

      case value === "last_7":
        setDateRange(today, lastWeek);
        break;

      case value === "last_month":
        setDateRange(startDate, lastMonth);
        break;
    }
  };

  const getValue = () => {
    if (!timestampLte || !timestampGte) return "";

    switch (true) {
      case compareDates(timestampLte, endDate) &&
        compareDates(timestampGte, startDate):
        return "this_month";
      case compareDates(timestampLte, today) &&
        compareDates(timestampGte, yesterday):
        return "last_24_h";
      case compareDates(timestampLte, today) &&
        compareDates(timestampGte, lastWeek):
        return "last_7";
      case compareDates(timestampLte, startDate) &&
        compareDates(timestampGte, lastMonth):
        return "last_month";
      default:
        return "";
    }
  };

  const menuItems: {
    label: string;
    type: PresetTypes;
  }[] = [
    {
      label: "None",
      type: "",
    },
    {
      label: "This Billing Cycle",
      type: "this_month",
    },
    {
      label: "Last 24 hours",
      type: "last_24_h",
    },
    {
      label: "Last 7 days",
      type: "last_7",
    },
    {
      label: "Last Billing Cycle",
      type: "last_month",
    },
  ];

  return (
    <Box data-testid="date-preset-select">
      <Select
        fullWidth
        size="small"
        value={getValue()}
        onChange={handleSelect}
        inputProps={{ "aria-label": "Without label" }}
        displayEmpty
      >
        {menuItems
          .filter((m) => !presetFilters || presetFilters.includes(m.type))
          .map((item, index) => (
            <MenuItem key={index} value={item.type} disabled={item.type === ""}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
}
