import { Box } from "@mui/material";
import useChart from "./hooks/useChart";
import { StatsType } from "@/types/StatsType";
import ComposedBarLineChart from "../ComposedBarLineChart";
import CustomStaticLegend from "./CustomStaticLegend";

interface IProps {
  stats: StatsType[] | undefined;
  timestampLte: Date | null;
  timestampGte: Date | null;
  maxY: number;
  primaryColor?: string;
}

export default function ProxyUsageChart({
  timestampLte,
  timestampGte,
  stats,
  maxY,
  primaryColor,
}: IProps) {
  const { data, ...chartProps } = useChart({
    timestampLte,
    timestampGte,
    stats,
    maxY,
  });

  return data ? (
    <ComposedBarLineChart
      primaryColor={primaryColor}
      {...chartProps}
      legendProps={{
        content: <CustomStaticLegend primaryColor={primaryColor} />,
      }}
      data={data}
      lineProps={{ dataKey: "bandwidth_total" }}
      barProps={{ dataKey: "average_concurrency" }}
      height={272}
    />
  ) : (
    <Box sx={{ height: 272, width: "100%" }} />
  );
}
