import { AlertTitle, Typography } from "@mui/material";
import { CallToActionAlert } from "../alerts/CallToActionAlert";
import { formCustomizePageHref } from "@/utils/formHref";
import { useSession } from "@/security/RouteGuards";
import {
  CustomizationSectionType,
  CustomizationSourceType,
} from "../subscription/customize/hooks/useSections";
import { findUserPlanSubMenuItem } from "../subscription/subscriptionHelpers";

import { getMenu } from "../subscription/menu/getMenu";
import { isPlanRotating } from "../subscription/customize/checkout/utils/rotatingPlanUtils";

export default function BandwidthUsageBanner({
  usageState,
  source,
}: {
  usageState: "error" | "warning";
  source: CustomizationSourceType;
}) {
  const { plan } = useSession();

  const { proxy_type, proxy_subtype, bandwidth_limit, proxy_count } =
    plan || {};

  if (!proxy_type || !proxy_subtype || !plan) return null;
  if (
    typeof bandwidth_limit === "undefined" ||
    typeof proxy_count === "undefined"
  )
    return null;

  const menu = getMenu();

  const isRotatingPlan = isPlanRotating(plan);

  const userPlanSubMenuItem = findUserPlanSubMenuItem(
    menu,
    plan,
    isRotatingPlan
  );

  const isResidential = proxy_subtype === "residential";

  if (!userPlanSubMenuItem) return null;

  const bandwidthMenuItems = userPlanSubMenuItem.bandwidthMenuItems;

  const maxBandwidth = Math.max(...bandwidthMenuItems.map((i) => i.bandwidth));

  const nextBandwidth = bandwidthMenuItems.find(
    (i) => i.bandwidth > bandwidth_limit
  );

  const moreBandwidthNeedsMoreProxies =
    bandwidth_limit >= maxBandwidth &&
    proxy_count < userPlanSubMenuItem.minCountForUnlimitedBandwidth;

  const showCustomizeSections: CustomizationSectionType[] | undefined = (() => {
    switch (true) {
      case isResidential && moreBandwidthNeedsMoreProxies:
        return undefined;
      case moreBandwidthNeedsMoreProxies:
        return ["bandwidth-plan", "proxy-count"];
      default:
        return ["bandwidth-plan"];
    }
  })();

  const additionalQueryParams = (() => {
    switch (true) {
      case moreBandwidthNeedsMoreProxies:
        return {
          proxyCount: userPlanSubMenuItem.minCountForUnlimitedBandwidth,
          bandwidth: 0,
        };
      case isResidential:
        return undefined;
      default:
        return { bandwidth: nextBandwidth?.bandwidth || bandwidth_limit };
    }
  })();

  const href = formCustomizePageHref({
    source,
    showCustomizeSections,
  });

  const query = { ...href.query, ...additionalQueryParams };

  return (
    <CallToActionAlert
      title="Increase Bandwidth"
      severity={usageState}
      href={{ ...href, query }}
    >
      <AlertTitle>
        {usageState === "warning"
          ? "Projected bandwidth usage is higher than the limit. You may exceed your bandwidth limit before the end of your subscription."
          : "You've used up all your bandwidth allowance and your proxies aren't working properly."}
      </AlertTitle>
      {usageState === "error" ? (
        <Typography variant="body2">
          {
            "To avoid interruptions in your proxy activity, you'll need to increase your bandwidth."
          }
        </Typography>
      ) : null}
    </CallToActionAlert>
  );
}
