import { CircularProgress, Grid } from "@mui/material";

import Typography from "@mui/material/Typography";
import AllInclusive from "@mui/icons-material/AllInclusive";
import { userReadableBytes } from "@/utils/textUtils";
import { FlexBox } from "../common/styles";
import { ColoredCard } from "@/ui/ColoredCard";
import { useSession } from "@/security/RouteGuards";

interface IProps {
  isAggregateStatsLoading: boolean;
  requests_total: number | undefined;
  bandwidth_limit: number | undefined;
  bandwidth_total: number | undefined;
  number_of_proxies_used: number | undefined;
}

const DAY_IN_S = 86400;

export default function ProxyUsageStats(props: IProps) {
  const {
    isAggregateStatsLoading,
    requests_total,
    bandwidth_limit,
    bandwidth_total,
    number_of_proxies_used,
  } = props;

  const { plan: { automatic_refresh_frequency } = {} } = useSession();
  const shouldShowNumberOfProxiesUsed =
    Number(automatic_refresh_frequency) >= DAY_IN_S;

  const gridSizeProps = shouldShowNumberOfProxiesUsed
    ? {
        xs: 6,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4,
      }
    : {
        xs: 6,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6,
      };

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid {...gridSizeProps} item>
        <ColoredCard type="grey" sx={{ height: "100%" }}>
          {isAggregateStatsLoading ? (
            <CircularProgress size={25} color="primary" />
          ) : (
            <Typography id="requestsTotalTypography">
              {requests_total?.toLocaleString()}
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            Total Requests
          </Typography>
        </ColoredCard>
      </Grid>

      <Grid {...gridSizeProps} item>
        <ColoredCard type="grey" sx={{ height: "100%" }}>
          {isAggregateStatsLoading ? (
            <CircularProgress size={25} color="primary" />
          ) : bandwidth_limit === 0 ? (
            <FlexBox sx={{ flexWrap: "wrap" }}>
              <Typography id="bandwidthTotalTypography">
                {userReadableBytes(bandwidth_total ?? 0)}
              </Typography>
              <Typography sx={{ mx: 2 }}>/</Typography>
              <AllInclusive fontSize={"small"} sx={{ mr: 2 }} />
              <Typography>GB</Typography>
            </FlexBox>
          ) : (
            <Typography id="bandwidthTotalTypography">
              <span>{userReadableBytes(bandwidth_total ?? 0)}</span> /{" "}
              <span>{bandwidth_limit?.toLocaleString()}</span> <span>GB</span>
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            Total Bandwidth used
          </Typography>
        </ColoredCard>
      </Grid>

      {shouldShowNumberOfProxiesUsed && (
        <Grid {...gridSizeProps} item>
          <ColoredCard type="grey" sx={{ height: "100%" }}>
            {isAggregateStatsLoading ? (
              <CircularProgress size={25} color="primary" />
            ) : (
              <Typography id="numberOfProxiesUsedTypography">
                {number_of_proxies_used?.toLocaleString()}
              </Typography>
            )}

            <Typography variant="body2" color="text.secondary">
              Number of proxies used
            </Typography>
          </ColoredCard>
        </Grid>
      )}
    </Grid>
  );
}
