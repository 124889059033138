import useSWR from "swr";
import { formBackendURL } from "../../src/utils/formURL";
import { fetcher } from "../../src/utils/fetcher";
import { formatISO } from "date-fns";
import { AggregateStatsType } from "../../src/types/AggregateStatsType";

import queryString from "query-string";
import { StatsType } from "../../src/types/StatsType";
import TimezoneUtils from "../utils/TimezoneUtils";

interface IProps {
  timestampLte: Date | null;
  timestampGte: Date | null;
}

export default function useStats({ timestampLte, timestampGte }: IProps) {
  const queryStats = queryString.stringify({
    timestamp__lte: timestampLte
      ? formatISO(TimezoneUtils.unzonedDate(timestampLte).setSeconds(0, 0))
      : undefined,
    timestamp__gte: timestampGte
      ? formatISO(TimezoneUtils.unzonedDate(timestampGte).setSeconds(0, 0))
      : undefined,
  });

  const stats = useSWR<StatsType[]>(
    formBackendURL(`/stats/?${queryStats}`),
    fetcher
  );

  const aggregateStats = useSWR<AggregateStatsType>(
    formBackendURL(`/stats/aggregate/?${queryStats}`),
    fetcher
  );

  return { stats, aggregateStats };
}
