import { Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import DatePresets, { PresetTypes } from "./DatePresets";
import useUrlState from "@/hooks/useUrlState";
import {
  DateTimeRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";

interface CustomDateTimeRangePickerProps {
  timestampGte: Date | null;
  timestampLte: Date | null;
  size?: "small" | "medium";
  justifyContent?: "left" | "right" | "center";
  minDate?: Date;
  maxDate?: Date;
  minutesInBetween?: number;
  withPresets?: boolean;
  presetFilters?: PresetTypes[];
}

const INPUT_FORMAT = "dd MMM yyyy hh:mm";

export default function CustomDateTimeRangePicker({
  timestampGte,
  timestampLte,
  size,
  justifyContent,
  minDate,
  maxDate,
  withPresets,
  presetFilters,
}: CustomDateTimeRangePickerProps) {
  const [, setTimestampLte] = useUrlState("activityTimestampLte");
  const [, setTimestampGte] = useUrlState("activityTimestampGte");

  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
      justifyContent={justifyContent}
      alignItems={{ md: "stretch", lg: "center" }}
      spacing={3}
    >
      <Stack
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        alignItems={{ xs: "stretch", sm: "stretch", md: "center" }}
        spacing={2}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimeRangePicker
            minutesStep={1}
            calendars={2}
            slotProps={{ textField: { size } }}
            ampm={false}
            format={INPUT_FORMAT}
            minDateTime={minDate}
            maxDateTime={maxDate}
            value={[timestampGte || null, timestampLte || null]}
            onChange={(newValue) => {
              setTimestampGte(newValue[0]?.toISOString());
              setTimestampLte(newValue[1]?.toISOString());
            }}
          />
        </LocalizationProvider>
      </Stack>
      {withPresets ? (
        <DatePresets
          timestampGte={timestampGte}
          timestampLte={timestampLte}
          justifyContent={justifyContent}
          presetFilters={presetFilters}
        />
      ) : null}
    </Stack>
  );
}
