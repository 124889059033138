import { useSession } from "@/security/RouteGuards";
import useUrlState from "./useUrlState";
import TimezoneUtils from "@/utils/TimezoneUtils";
import { isValid, startOfDay, subDays } from "date-fns";
import logToSentry from "@/utils/logToSentry";

export function useSubscriptionSafeTimeStamps() {
  // presets
  const { subscription } = useSession();

  const { start_date, end_date } = subscription || {};

  if (!start_date || !end_date) {
    logToSentry("subscription date error undefined dates", {
      extras: [["errorData", JSON.stringify(subscription)]],
    });
  } else if (!isValid(new Date(start_date) || !isValid(new Date(end_date)))) {
    logToSentry("subscription date error invalid dates", {
      extras: [["errorData", JSON.stringify(subscription)]],
    });
  }

  const [timestampLte] = useUrlState(
    "activityTimestampLte",
    TimezoneUtils.zonedDate(end_date).toISOString(),
    "replace"
  );
  const [timestampGte] = useUrlState(
    "activityTimestampGte",
    TimezoneUtils.zonedDate(start_date).toISOString(),
    "replace"
  );

  const lteDate = timestampLte ? new Date(timestampLte) : null;
  const gteDate = timestampGte ? new Date(timestampGte) : null;

  const minDate = startOfDay(subDays(TimezoneUtils.zonedDate(), 90));

  const maxDate = TimezoneUtils.zonedDate(end_date);

  const lteSafe = lteDate && lteDate > maxDate ? maxDate : lteDate;
  const gteSafe = gteDate && gteDate < minDate ? minDate : gteDate;
  return { minDate, lteSafe, gteSafe, maxDate };
}
