import { useMemo } from "react";
import { AxisDomain } from "recharts/types/util/types";
import { AxisPropType } from "../../ComposedBarLineChart/types";
import { StatsType } from "@/types/StatsType";
import { userReadableBytes } from "../../../../utils/textUtils";
import { format } from "date-fns";
import TimezoneUtils from "../../../../utils/TimezoneUtils";
import { getDatesInRange } from "../../../../utils/timeUtils";
import { Paper, Typography } from "@mui/material";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

interface IProps {
  timestampLte: Date | null;
  timestampGte: Date | null;
  stats: StatsType[] | undefined;
  maxY: number;
}

export default function useChart(props: IProps) {
  const { timestampLte, timestampGte, stats, maxY } = props;

  const data = useMemo(() => {
    if (!stats || !Array.isArray(stats)) return;

    return stats.reduce<(StatsType & { time: number })[]>(
      (acc, value, index) => {
        let prevVal = 0;
        if (index > 0) {
          prevVal = acc[index - 1].bandwidth_total;
        }

        acc.push({
          ...value,
          time: TimezoneUtils.zonedDate(value.timestamp).getTime(),
          average_concurrency: Math.ceil(value.average_concurrency),
          bandwidth_total: value.bandwidth_total + prevVal,
        });

        return acc;
      },
      []
    );
  }, [stats]);

  const percentage = useMemo(() => {
    const dataLength = stats?.length;
    if (!stats || !Array.isArray(stats) || !dataLength) return 0;
    const nonProjectedLength = stats.filter(
      (item) => !item.is_projected
    ).length;

    return (nonProjectedLength / dataLength) * 100;
  }, [stats]);

  const xDomain = () => [
    timestampGte?.getTime() ?? 0,
    timestampLte?.getTime() ?? 0,
  ];

  const xAxisProps = {
    domain: xDomain(),
    type: "number" as AxisPropType,
    dataKey: "time",
    tickFormatter: (value: number) => format(value, "MMM dd"),
    ticks: getDatesInRange(timestampGte, timestampLte),
  };

  const yAxisProps = {
    tickFormatter: (value: number) => userReadableBytes(value),
    type: "number" as AxisPropType,
    domain: [0, maxY],
    ticks: [0, maxY / 4, maxY / 2, (maxY / 4) * 3, maxY],
    hide: maxY === 0,
  };

  const endYaxisProps = {
    type: "number" as AxisPropType,
    domain: [0, (dataMax: number) => dataMax * 1.4] as AxisDomain,
    hide: true,
  };

  const tooltipProps = {
    content: ({
      active,
      payload,
      label,
    }: TooltipProps<ValueType, NameType>) => {
      if (active && payload && payload.length) {
        return (
          <Paper sx={{ px: 3, py: 2, borderRadius: "8px" }} variant="outlined">
            <Typography variant="overline">
              {format(label, "MMM dd, HH:mm")}
            </Typography>
            <Typography sx={{ mb: 1 }} fontWeight="bold">
              <span>Average Concurrency : </span>{" "}
              <span>{payload[0].value}</span>
            </Typography>
            {typeof payload[1].value === "number" ? (
              <Typography fontWeight="bold">
                <span>Bandwidth total :</span>{" "}
                <span>{userReadableBytes(payload[1].value)}</span>
              </Typography>
            ) : null}
          </Paper>
        );
      }

      return null;
    },
  };

  return {
    data,
    percentage,
    xAxisProps,
    yAxisProps,
    endYaxisProps,
    tooltipProps,
  };
}
