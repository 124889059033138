import { AlertColor, Box, Button } from "@mui/material";
import Alert from "@/components/alerts/Alert";
import { NextLink } from "@/components/common/styles";
import { HrefType } from "@/utils/formHref";

interface CallToActionAlertProps {
  children: React.ReactNode;
  href: HrefType | string;
  title: string;
  severity?: AlertColor;
}

export function CallToActionAlert(props: CallToActionAlertProps) {
  const { children, href, title, severity } = props;
  return (
    <Alert
      severity={severity}
      action={
        <NextLink href={href} passHref>
          <Button
            disableElevation
            color="inherit"
            size="small"
            variant="contained"
          >
            {title}
          </Button>
        </NextLink>
      }
    >
      <Box>{children}</Box>
    </Alert>
  );
}
