import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import { FlexCentered } from "../../../common/styles";

export const Holder = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

export const LabelHolder = styled(FlexCentered)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

export const DisabledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.text.disabled,
  height: 8,
  width: 16,
  marginRight: theme.spacing(2),
}));

export const MainBox = styled(Box, {
  shouldForwardProp: (propName) => propName !== "primaryColor",
})<{
  primaryColor?: string;
}>(({ theme, primaryColor }) => ({
  background: primaryColor ?? theme.palette.primary.main,
  height: 4,
  width: 16,
  marginRight: theme.spacing(2),
}));

export const LightBox = styled(Box, {
  shouldForwardProp: (propName) => propName !== "primaryColor",
})<{
  primaryColor?: string;
}>(({ theme, primaryColor }) => ({
  background: primaryColor ?? theme.palette.primary.main,
  height: 2,
  width: 16,
  opacity: 0.3,
  marginRight: theme.spacing(2),
}));
